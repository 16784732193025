<template>
  <secondary-page-layout :title="title" :info-link="true" :product="product">
    <div class="form-group px-3">
      <component :is="getPolicyForm" ref="productForm"></component>
    </div>
  </secondary-page-layout>
</template>

<script>
import CheckboxAndSelect from "../components/BuyPolicy/CheckboxAndSelect";
import CreditCardSelect from "../components/BuyPolicy/CreditCardSelect";
import FinalAmount from "../components/BuyPolicy/FinalAmount";
import SelectOptions from "../components/BuyPolicy/Dropdown";
import OgpoForm from "../components/PolicyForms/OgpoForm";
import CascoLiteForm from "@/components/PolicyForms/CascoLiteForm";
import FlatOnlineForm from "../components/PolicyForms/FlatOnlineForm";
import MyHouseForm from "../components/PolicyForms/MyHouseForm";
import TravelerForm from "../components/PolicyForms/TravelerTempo/TravelerForm";
import CheerUpForm from "../components/PolicyForms/CheerUpForm";
import MostImportant from "../components/PolicyForms/MostImportant";
import CovidProtect from "../components/PolicyForms/TravelerTempo/CovidProtect";

import CascoSubscriptionForm from "@/components/PolicyForms/CascoSubscriptionForm";
import FlatSubscriptionForm from "@/components/PolicyForms/FlatSubscriptionForm";
import HouseSubscriptionForm from "@/components/PolicyForms/HouseSubscriptionForm";

import {mapGetters} from "vuex";

export default {
  name: "PolicyForm",
  components: {
    SelectOptions,
    FinalAmount,
    CreditCardSelect,
    CheckboxAndSelect,
    OgpoForm,
    CascoLiteForm,
    FlatOnlineForm,
    MyHouseForm,
    TravelerForm,
    CheerUpForm,
    MostImportant,
    CovidProtect,
    CascoSubscriptionForm,
    FlatSubscriptionForm,
    HouseSubscriptionForm,
  },
  async created() {
    await this.getProduct();
  },
  computed: {
    ...mapGetters({
      product: "INSURANCE_PRODUCT"
    }),

    getPolicyForm() {
      if(this?.product?.insurance_type == '2225'){
        return 'casco-subscription-form';
      }else if(this?.product?.insurance_type == "3014"){
        return 'flat-subscription-form';
      }else if(this?.product?.insurance_type == '3016'){
        return 'house-subscription-form';
      }else{
        return this?.product?.form_component;
      }
    },
    title() {
      return this?.product?.title;
    }
  },
  methods: {
    async getProduct() {
      const { data } = await window.axios.get(`insurance-products/${this.$route.params.id}`);

      this.$store.commit("SET_INSURANCE_PRODUCT", data)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

</style>
