import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import bonuses from "./bonuses";

export default {
  mixins: [bonuses],
  data(){
    return {
      phone_number: null,
      initialDate: null,
      agreement_id: null
    }
  },
  computed:{
    ...mapGetters({
      listCities: 'dictionary/cityList',
      authUser: 'authUser',
      insuranceProduct: 'INSURANCE_PRODUCT'
    }),
  },
  async created() {
    const loader = this.$loading.show({})

    this.phone_number = `7${this.authUser.phone_number}`;

    await this.getCityList()

    // Инициализация начальных данных
    if (this.$route.query.agreement_id) {
      await this.setAgreementInfo();
    } else {
      await this.setDefaultInfo();
    }

    if(this.agreement_id) {
      window.addEventListener('unload', this.resetForm)
    }

    loader.hide();
  },

  beforeDestroy() {
    if(this.agreement_id) {
      this.resetForm();
    }
  },

  methods: {
    ...mapActions({
      getCityList: 'dictionary/getCityList'
    }),

    async onSubmit() {
      let loader = this.$loading.show({});

      //Потом коммет убери 
      await this.checkClients();

      if (this.clientsChecked === false) {
        loader.hide();
        return
      }
      //Потом коммет убери 

      try {
        let success = await this.$refs.form.validate()

        if (success) {
          const { id, amount } = await this.calculation();
          this.amount = amount;

          loader.hide()

          await this.$router.push({
            name: 'insurance.products.checkout',
            params: {
              id: id
            },
          })
        }
      } catch (err) {

        this.onError({
          title: `Ошибка при калькуляции полиса`,
          message: (err?.response?.data?.message || err?.message),
          code: err?.response?.data?.error_code || 0
        })

        loader.hide();
      }
    },

    setInitialDate(endDate) {
      const startDate = moment().diff(endDate) > 0 ? moment().add(1, "days").format("YYYY-MM-DD") : moment(endDate).format("YYYY-MM-DD");

      this.changeStartDate(startDate);

      this.initialDate = startDate;
    },
  }
}