export default {
  data() {
    return {
      clientID: null
    }
  },
  methods: {
    async changeSubsHolder({ item }) {

      if(item.client_id && this.clientID !== item.client_id) {
        this.clientID = item.client_id;
        await this.getTariffs(item.client_id)

        this.changeTariffCalc(0, "LIGHT");
      }

      await this.changeHolder({ item });
    },

    async getFullTariff() {
      try {
        const {data} = await window.axios.get(this.tariffUrl);

        data.sort(function(a, b) {
          return parseFloat(a.premMonth) - parseFloat(b.premMonth);
        });

      } catch (e) {
        throw e
      }finally {
        this.loadingActive = false
      }
    },

    async getTariffs(clientId = null) {
      const { $loading } = this
      const loader = $loading.show({});
      try {
        let uri = this.tariffUrl;

        if(clientId) {
          uri += '?clientId='+clientId
        }

        const {data} = await window.axios.get(uri);

        data.sort(function(a, b) {
          return parseFloat(a.premMonth) - parseFloat(b.premMonth);
        });

        this.tariffs = data;

        this.changeTariffCalc(0, 'LIGHT');
      } catch (e) {
        throw e
      }finally {
        this.loadingActive = false;
        loader.hide();
      }
    },

    changeRate(index){
      this.rates_index = index;
      let name = this.rates[index].name;
      this.changeTariff(name);
    },

    changeTariffCalc: function (index, title) {
      let name = title;

      let selectedTariff = this.tariffs.find(item => Number(item.premMonth) === Number(this.tariffs[index].premMonth));
      name = selectedTariff?.tarif;

      this.finalAmountObj = {
        amount: selectedTariff.total,
        bonus_value: 0,
        fix_sum: selectedTariff.total,
      }

      if(selectedTariff.isSale) {
        this.finalAmountObj.amount = selectedTariff.premMonthSumm;
        this.finalAmountObj.bonus_value = selectedTariff.premThreeMonthSumm || 0
      }

      if (name == 'LIGHT') {
        name = 'Легкий';
      } else if (name == 'STANDARD') {
        name = 'Стандартный';
      } else {
        name = 'Оптимальный';
      }

      this.changeTariff(name);
    },
  }
}
