import moment from "moment";
import _ from "lodash";

export default {
  methods: {
    onError: _.throttle(function (error){
      try{
        window.axios.post('/redmine',
            {
              product_id: this.form.insurance_type,
              datetime: moment().format("DD.MM.YYYY HH:mm"),
              type: "error",
              error_message : error?.message,
              error_code: error?.code,
              error_title: error?.title,
              stage : 2,
              url: window.location.href,
              user_data: {
                phone_number: this.form.phone_number,
                individual_id_number: this.form?.holder?.individual_id_number,
                reg_number: this.form?.vehicle?.license_plate
              },
              form_data: this.form
            }
        );
      } catch (e) {
        console.log(e)
      }
    }, 5000)
  }
}