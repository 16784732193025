export default {
  computed: {
    availableBonuses() {
      return this.insuranceProduct?.bonuses
    },

    bonusValue(){
      if(!this.bonusProduct || !this.availableBonuses){
        return null
      }

      const selectedItem = this.availableBonuses.find(item => item.insisID === this.bonusProduct)

      if(!selectedItem){
        return null
      }

      let {bonus_value} = selectedItem;

      switch (selectedItem.type) {
        case 'bonus':
          let bv = parseInt(this.finalAmount * bonus_value / 100);
          bv = bv > 30000 ? 30000: bv;
          return bv;
        case 'bonus_fixed':
          return value;
        default:
          return null;
      }
    },

    bonusTitle(){
      if (!this.bonusProduct || !this.availableBonuses) {
        return null
      }

      const selectedItem = this.availableBonuses.find(item => item.insisID === this.bonusProduct)

      return selectedItem?.bonus_title;
    },
  }
}